import React from "react";
import { Card, Container } from "reactstrap";

export function MyPdfViewer({ file }) {
  
  return (
    <Container>
      <div className="pb-3">
        <Card>
          <embed
            src={file}
            width="100%"
            height="500"
            alt="pdf"
            pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"
          />
        </Card>
      </div>
    </Container>
  );
}

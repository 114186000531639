import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import loadingGif from "../../assets/gif/loading.gif";
import { MyPdfViewer } from "../MyPdfViewer";
import { MyJpgViewer } from "../MyJpgViewer";
import ClientCaptcha from "react-client-captcha";

export function Formulario() {
  const [csv, setCsv] = useState("");
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState("");
  const [jpg, setJpg] = useState("");
  const [codigoCaptcha, setCodigoCaptcha] = useState("");
  const [codigoGenerado, setCodigoGenerado] = useState("");
  const [allOk, setAllOk] = useState(false);

  const onSubmit = async () => {
    if (csv !== "" && codigoCaptcha !== codigoGenerado) {
      alert("El código introducido no se corresponde con el de la imagen");
    }
    if (codigoCaptcha === codigoGenerado && csv !== "") {
      //callIP();
      setLoading(true);
      await callPDF();
    }
  };

  const callPDF = () => {
    fetch(`${process.env.REACT_APP_URL_BACKEND}/search/pdf/${csv}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((respuesta) => {
        if (respuesta.ok) {
          return respuesta.blob();
        } else if (respuesta.status === 404) {
          alert(
            "No se encontra el documento solicitado, verifique CSV introducido"
          );
          setLoading(false);
          window.location.reload(false);
          throw new Error(
            "No se encontra el documento solicitado, verifique CSV introducido"
          );
        } else if (respuesta.status > 404) {
          setLoading(false);
          window.location.reload(false);
          throw new Error(
            "No se encontra el documento solicitado, verifique CSV introducido"
          );
        }
      })
      .then((resp) => {
        setPdf(window.URL.createObjectURL(resp));
        callJPG();
      })
      .catch((error) => {
        setLoading(false);
        alert(error.message);
      });
  };

   const callJPG = async () => {
    fetch(`${process.env.REACT_APP_URL_BACKEND}/search/jpg/${csv}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((respuesta) => {
        if (respuesta.ok) {
          return respuesta.blob();
        } else if (respuesta.status === 404) {
          alert(
            "No se encontra el documento solicitado, verifique CSV introducido"
          );
          setLoading(false);
          window.location.reload(false);
          throw new Error(
            "No se encontra el documento solicitado, verifique CSV introducido"
          );
        } else if (respuesta.status > 404) {
          setLoading(false);
          window.location.reload(false);
          alert(
            "Se produjo un error inesperado, inténtelo de nuevo más tarde..."
          );
          return false;
        }
      })
      .then((resp) => {
        setLoading(false);
        setAllOk(true);
        setJpg(window.URL.createObjectURL(resp));
      })
      .catch((error) => {
        setLoading(false);
        alert(
          "Se produjo un error inesperado, inténtelo de nuevo más tarde..."
        );
      });
  };

  useEffect(() => {
    return () => {
      URL.revokeObjectURL("");
    };
  }, []);

  const handleChange = (e) => {
    //e.preventDefault();
    setCsv(e.target.value);
  };

  const handleChangeInputCodigo = (e) => {
    //e.preventDefault();
    setCodigoCaptcha(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCsv(csv);
    onSubmit();
  };

  const LABEL_CHECK = "Comprobar CSV";
  const LABEL_NEW_CHECK = "NUEVA COMPROBACIÓN";

  return (
    <>
      {!allOk ? (
        <Form role="form" onSubmit={handleSubmit}>
          <FormGroup className="mb-2">
            <InputGroup className="input-group">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-file-text" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Introduzca Código Seguro de Verificación"
                type="text"
                onChange={handleChange}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="mb-2">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "8vh",
              }}
            >
              <ClientCaptcha
                retry={false}
                fontFamily="Arial"
                captchaCode={(code) => setCodigoGenerado(code)}
                charsCount={6}
                fontSize={14}
              />
            </div>
            <Input
              placeholder="Introduzca el texto que aparece en la imagen"
              type="text"
              onChange={handleChangeInputCodigo}
            />
          </FormGroup>

          <div className="text-center">
            <Button
              disabled={loading}
              className="my-4"
              color="primary"
              type="submit"
            >
              {LABEL_CHECK}
            </Button>
          </div>
        </Form>
      ) : (
        <div>
          <MyPdfViewer file={pdf}></MyPdfViewer>
          <MyJpgViewer file={jpg}></MyJpgViewer>{" "}
          <div className="text-center">
            <Button
              disabled={loading}
              className="my-4"
              color="primary"
              //onClick={handleRepeatSearch}
              onClick={() => {
                window.location.reload(false);
              }}
            >
              {LABEL_NEW_CHECK}
            </Button>
          </div>
        </div>
      )}

      {loading ? (
        <>
          <div>
            <img src={loadingGif} width="150" height="150" alt="loading..." />{" "}
          </div>{" "}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

import React from 'react'
import { Button, Col, Container, Row, UncontrolledTooltip } from 'reactstrap'

export function Footer () {
    return (
        <>
        <footer className=" footer has-cards">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
              <Col lg="8">
                <h3 className=" text-primary font-weight-light mb-2">
                  
                </h3>
                <h6 className=" mb-0 font-weight-light">
                  
                </h6>
              </Col>
              <Col className="text-lg-right btn-wrapper" lg="4">
              <Button
                  className="btn-icon-only rounded-circle"
                  color="slack"
                  href="https://www.juntaex.es"
                  id="tooltip475038074s"
                  target="_blank" rel="noreferrer" 
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-globe" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074s">
                JuntaEx
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                  href="https://twitter.com/Junta_Ex"
                  id="tooltip475038074"
                  target="_blank" rel="noreferrer"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-twitter" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  @Junta_Ex
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href="https://www.facebook.com/JuntaDeExtremadura/"
                  id="tooltip837440414"
                  target="_blank" rel="noreferrer"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                @JuntaDeExtremadura
                </UncontrolledTooltip>
                </Col>
            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                    href="http://www.juntaex.es/web/"
                    target="_blank" rel="noreferrer"
                  >
                    Junta de Extremadura
                  </a>
                  .
                </div>
              </Col>
             
            </Row>
          </Container>
        </footer>
      </>
    )
}

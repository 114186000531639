import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

export function ArrayCards() {
  return (
    <>
      <section className="section section-lg pt-lg-0 mt--200">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <Row className="row-grid">
              <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                        <i className="ni ni-tag" />
                      </div>
                      <h6 className="text-primary text-uppercase">
                        ¿Qué es CSV?
                      </h6>
                      <p className="description mt-3">
                      Es un código alfanumérico impreso en un documento que lo identifica de manera única y que permite su verificación y posterior recuperación online.
                      </p>
                    
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                        <i className="ni ni-single-02" />
                      </div>
                      <h6 className="text-success text-uppercase">
                        ¿Quién puede acceder?
                      </h6>
                      <p className="description mt-3">
                      Cualquier persona que tenga en su poder un documento en formato electrónico o en papel con CSV y necesite comprobar la validez
                      de dicho documento.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                        <i className="ni ni-cloud-download-95" />
                      </div>
                      <h6 className="text-primary text-uppercase">
                        Documento Original
                      </h6>
                      <p className="description mt-3">
                      La aplicación le requerirá los digitos que forman el CSV
                      y le devolverá el documento electrónico equivalente con las firmas electrónicas incorporadas
                      junto a la huella de firma vinculada.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
            
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef } from "react";

// reactstrap components
import { Card, Container, Row } from "reactstrap";
import { Formulario } from "../components/Formulario";

export default function Search() {
  const main = useRef(null);

  return (
    <>
      <main className="profile-page" ref={main}>
        <section className="section-profile-cover section-shaped my-0">
          {/* Circles background */}
          <div className="shape shape-style-1 shape-default alpha-4">
            <span />
            <span />
          </div>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <section className="section">
          <Container>
            <Card className="card-profile shadow mt--300">
              <div className="px-9">
                <Row className="justify-content-center">
                  <div className="text-center mt-5 col-12">
                    <Formulario></Formulario>
                  </div>
                </Row>
              </div>
            </Card>
          </Container>
        </section>
        <section className="section">
          <Container>
            <Card></Card>
          </Container>{" "}
        </section>
      </main>
    </>
  );
}

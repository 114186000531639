import React, { useRef } from "react";
import { Content } from "../components/Content";

export default function Spa() {
  const main = useRef("main");

  return (
    <>

        <main ref={main}>
          <Content/>
        </main>

    </>
  );
}

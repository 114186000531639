import React, { useRef } from "react";
import { Link } from "wouter";
import { Button, Col, Container, Row } from "reactstrap";

import { ArrayCards } from "../ArrayCards";

export function Content() {
  const main = useRef(null);

  return (
    <>
      <main ref={main}>
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    <h1 className="display-3 text-white">
                      Plataforma Tabul@rium{" "}
                      <span>Código Seguro de Verificación (CSV)</span>
                    </h1>
                    <p className="lead text-white">
                      Los documentos firmados mediante la plataforma Tabul@rium
                      tienen impreso en el documento un Código Seguro de
                      Verificación (CSV). Este código le permite comprobar la
                      autenticidad de dicho documento.
                    </p>
                    <div className="btn-wrapper">
                      <Link to="/check">
                        <Button className="btn-icon mb-3 mb-sm-0" color="info">
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-check-square-o" />
                          </span>
                          <span className="btn-inner--text">Comprobar CSV</span>
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>

            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
        </div>
        <ArrayCards />
      </main>
    </>
  );
}

import React from "react";
import { Card, Container } from "reactstrap";

export function MyJpgViewer({ file }) {

  return (
    <Container>
      <div className="pb-3">
        <Card>
          <img src={file} alt="Huella"/>
        </Card>
      </div>
    </Container>
  );
}

import "./App.css";
import "./assets/css/argon-design-system-react.css";
import Spa from "./views/Spa";
import { Footer } from "./components/Footer";
import { Redirect, Route, Switch } from "wouter";
import Search from "./views/Search";
import { BrowserRouter } from "react-router-dom";
import { NavBar } from "./components/NavBar";

function App() {
  return (
    <>
      <NavBar />
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Spa} />
          <Route path="/check" component={Search} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;

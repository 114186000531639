import React from "react";
import { Container, Media, Navbar, NavbarBrand } from "reactstrap";
import LogoImagen from "../../assets/img/brand/argon-react-white.png";

export function NavBar() {

  return (
    <>
      <header className="header-global">
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand className="mr-lg-5" href="/">
              <Media left>
                <Media object src={LogoImagen} alt="Logo" />
              </Media>
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon"></span>
            </button>
          </Container>
        </Navbar>
      </header>
    </>
  );
}
